var projectSettings = {
	inlineSVG: [
		'.theme-pulitzer .header__logo img',
		'.theme-pulitzer .footer__social img',
		'.theme-riggs .header__logo img',
		'.theme-sea-co .header__logo img',
		'.theme-sea-co .sea-co-footer__contact-social img',
		'.header__login-button img',
		'.off-canvas__nav-footer-primary--login ul li img'
	]
};